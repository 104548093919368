.reasons{
    display: flex;
    padding: 2rem;
    flex-direction: row;
}

.left-r{
    flex:1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    grid-auto-rows: 1fr;
}

.left-r img{
    object-fit: cover;
}

.left-r>:nth-child(1){
    width: 12rem;
    grid-row: 1/3;
    height: 30rem;
}

.left-r>:nth-child(2){
    width: 28rem;
    grid-column: 2/4;
}

.left-r>:nth-child(3){
    width: 12rem;
    height: auto;
    height: 10rem;
}

.left-r>:nth-child(4){
    height: 10rem;
}

.right-r{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-transform: uppercase;
    color: white;
}

.right-r>:nth-child(1){
    color: var(--orange);
    font-weight: bolder;
}

.right-r>:nth-child(2){
    font-size: 3rem;
    font-weight: bolder;
}

.right-r span>img{
    width: 2rem;
}

.right-r div:nth-of-type(1){
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.right-r div:nth-of-type(1) span{
    display: flex;
    align-items: center;
    font-weight: bolder;
}

.right-r div:nth-of-type(1) span>img{
    padding-right: 1rem;
}

.right-r div:nth-of-type(2) >img{
    padding-right: 1rem;
}

@media screen and (max-width: 769px) {
    .reasons{
        flex-direction: column;
    }

    .left-r>:nth-child(1){
        width: 5rem;
        grid-row: 1/3;
        height: 13rem;
    }
    
    .left-r>:nth-child(2){
        width: 10rem;
        grid-column: 2/4;
    }
    
    .left-r>:nth-child(3){
        width: 5em;
        height: 4rem;
    }
    
    .left-r>:nth-child(4){
        height: 4rem;
    }
  
    .right-r>:nth-child(2){
        font-size: xx-large;
        text-align: center;
    }
    
    .right-r span>img{
        width: 1rem;
    }
}
