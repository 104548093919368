.programs-title{
    font-size: 3.2rem;
    text-transform: uppercase;
    color: white;
    display: flex;
    font-weight: bolder;
    justify-content: center;
    flex-direction: row;
    gap: 5rem;
    font-style: italic;
}

.programs-card{
    display: flex;
    gap: 2rem;
    padding: 1rem 2rem;
}

.programs-card .card{
    display: flex;
    color: white;
    flex-direction: column;
    background-color: var(--gray);
    padding: 2rem;
    cursor: pointer;
    gap: 1rem;
}

.programs-card .card:hover{
    background-image: linear-gradient(45deg, rgb(255, 174, 0) 10%, rgb(255, 105, 5) 80%);
}

.programs-card .card span:nth-of-type(1)>i{
    font-size: 2rem;
}

.programs-card .card span:nth-of-type(2){
    font-weight: bolder;
    font-size: 1.1rem;
}

.programs-card .card span:nth-of-type(4){
    font-size: 1.1rem;
}

.programs-card .card span:nth-of-type(4) i{
    padding-left: 2rem;
}

@media screen and (max-width: 769px){
    .programs-title{
        font-size: xx-large;
        flex-direction:column;
        gap: 1rem;
        text-align: center;
        margin-top: -5rem;
    }

    .programs-card{
        flex-direction: column;
    }

    .programs-card .card{
        font-size: small;
    }
}