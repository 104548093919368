.review{
    display: flex;
    gap: 1rem;
    padding: 1rem 2rem;
    color: white;
}

.left-rv{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 60%;
}

.left-rv>span:nth-of-type(1){
    color: var(--orange);
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.2rem;
}

.left-rv span:nth-of-type(1){
    color: var(--orange);
}

.left-rv span:nth-of-type(2),
.left-rv span:nth-of-type(3){
    font-size: 3.2rem;
    font-weight: bold;
    text-transform: uppercase;
}

.left-rv span:nth-of-type(3){
    color: white;
}

.right-rv img{
    width: 20rem;
    height: 25rem;
}

.right-rv{
    position: relative;
    display: flex;
}

.right-rv .arrows{
    margin-top: 70%;
    margin-right: 20%;
}

.right-rv .arrows i{
    font-size: 2rem;
    padding: 10px;
    cursor: pointer;
}

.right-rv div:nth-of-type(2){
    position: absolute;
    border: 3px solid var(--orange);
    width: 20rem;
    height: 25rem;
    top: -5%;
    left: 25%;
    z-index: -1;
}

.right-rv div:nth-of-type(3){
    position: absolute;
    background:  var(--orange);
    width: 20rem;
    height: 25rem;
    top: 5%;
    left: 38%;
    z-index: -1;
}

@media screen and (max-width: 769px){
    .review{
        display: flex;
        flex-direction: column;
    }

    .left-rv>span:nth-of-type(2),
    .left-rv>span:nth-of-type(3){
        font-size: xx-large;
        padding-top: 1rem;
    }

    .left-rv{
        gap: 0;
        width: 100%;
        margin-bottom: 1rem
    }

    .left-rv>span:nth-of-type(1){
        font-size: smaller;
    }

    .left-rv>span:nth-of-type(4),
    .left-rv>span:nth-of-type(5){
        font-size: small;
        padding-top: 1rem;
    }

    .right-rv .arrows i{
        font-size: 1rem;
    }

    .right-rv img{
        width: 8rem;
        height: 12rem;
        padding-right: 70px;
    }

    .right-rv div:nth-of-type(2){
        width: 8rem;
        height: 12rem;
    }
    
    .right-rv div:nth-of-type(3){
        width: 8rem;
        height: 12rem;
    }
}
