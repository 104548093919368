.hero{
    display: flex;
    justify-content: space-between;
}

.left-h{
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
}

.right-h{
    flex: 1;
}

.the-best-ad{
    background-color: #363d46;
    padding: 20px 13px;
    border-radius: 3rem;
    width: fit-content;
    color: white;
    text-transform: uppercase;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 6rem;
}

.the-best-ad>span{
    z-index: 2;
}

.the-best-ad>div{
    position: absolute;
    background-color: var(--orange);
    width: 5.4rem;
    height: 80%;
    z-index: 1;
    left: 10px;
    border-radius: 3rem;
    animation-name: righttoleft;
    animation-duration: 3s;  
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}

@keyframes righttoleft {
    from {left: 70%}
    to {left: 10px;}
  }

.hero-text{
    color: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
}

.hero-text div{
    font-size: 72px;
    text-transform: uppercase;
    font-weight: bolder;
}

.hero-features{
    color: white;
    display: flex;
    flex-direction: row;
    gap: 3rem;
    margin-top: 1rem;
}

.hero-features div{
    display: flex;
    flex-direction: column;
}

.hero-features div>span:first-child{
    font-size: 2rem;
}

.hero-features div>span:last-child{
    color: var(--gray);
}

.hero-buttons{
    display: flex;
    flex-direction: row;
    gap:1rem;
    margin-top: 2rem;
}

.right-h{
    background-color: var(--orange);
    position: relative;
}

.right-h button{
    position: absolute;
    top: 6%;
    right: 15%;
}

.hero-heart{
    background-color: var(--darkGrey);
    position: absolute;
    top: 15%;
    right: 18%;
    padding: 1rem;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: .5rem
}

.hero-heart>img{
    width: 2rem;
}

.hero-heart>span:nth-of-type(1){
    color: var(--gray);
    font-size: 1rem;
}

.hero-heart>span:nth-of-type(2){
    font-size: 1.7rem;
}

.right-h>img:nth-of-type(1){
    width: 80%;
    position: absolute;
    top: 25%;
    left: -10%;
}

.right-h>img:nth-of-type(2){
    position: absolute;
    top: 20%;
    left: -20%;
    z-index: -1;
}

.hero-calories{
    background-color: var(--caloryCard);
    position: absolute;
    top: 70%;
    left: -36%;
    display: flex;
    padding: 1rem;
    border-radius: .3rem;
}

.hero-calories>img{
    width: 3.5rem;
}

.calories-text{
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
}

.calories-text>span:nth-of-type(1){
    color: var(--gray);
    font-size: 1.1rem;
}
.calories-text>span:nth-of-type(2){
    color: white;
    font-size: 1.7rem;
}

@media screen and (max-width: 769px) {

    .hero{
        flex-direction: column;
    }
    
    .blur{
        width: 12rem;
    }

    .the-best-ad{
        font-size: small;
    }

    .hero-text div{
        font-size: xx-large;
    }

    .hero-text p{
        font-size: small;
    }
    
    .hero-features{
        font-size: small;
    }
    
    .hero-features div>span:first-child{
        font-size: small;
    }

    .right-h{
        background:none;
        position: relative;
        margin-bottom: 170%;
    }

    .hero-calories{
        left: 47%;
        margin-top: 20%;
    }

    .hero-heart{
        right: 60%;
    }

    .hero-heart>span:nth-of-type(1){
        font-size: small;
    }
    
    .hero-heart>span:nth-of-type(2){
        font-size:large;
    }

    .calories-text>span:nth-of-type(1){
        font-size: small;
    }

    .calories-text>span:nth-of-type(2){
        font-size: small;
    }

    .hero-calories>img{
        width: 3rem;
    }

    .right-h>img:nth-of-type(1){
        margin-top: 55%;
        left: 25%;
    }
    
    .right-h>img:nth-of-type(2){
        margin-top: 55%;
        left: 15%;        
    }

    @keyframes righttoleft {
        from {left: 60%}
        to {left: 10px;}
      }
}