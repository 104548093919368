.contact{
    display: flex;
    padding: 1rem 2rem;
    width: 70%;
}

.left-c>div:nth-child(1){
    border: 2px solid var(--orange);
    width: 15rem;
    height: 0;
    border-radius: 40%;
}

.left-c{
    font-size: 3.2rem;
    font-weight: bolder;
    color: white;
    text-transform: uppercase;
}

.right-c{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.right-c input{
    border: none;
    outline: none;
    padding: 30px;
    font-size: 1rem;
    background: var(--gray);
    color: var(--lightgray);
    width: 200px;
    padding-right: 120px;
}

.right-c input::selection{
    color: orange;
}

::placeholder {
    color: var(--lightgray);
}

.right-c button{
    position: absolute;
    top: 37.5%;
    left: 62%;
}

@media screen and (max-width: 769px ){
    .contact{
        flex-direction: column;
        text-align: center;
    }

    .left-c{
        font-size: xx-large;
    }
    
    .right-c {
        padding-left: 32%;
        padding-top: 2rem;
    }

    .right-c input{
        width: 150px;
        padding: 20px;
        font-size: .7rem;
        background: var(--gray);
        color: var(--lightgray);
        padding-right: 100px;
    }

    .right-c button{
        top: 47.5%;
        left: 95%;
    }
}

