.plans-title{
    display: flex;
    justify-content: center;
    color: white;
    font-weight: bolder;
    font-size: 3.2rem;
    text-transform: uppercase;
    gap: 3.5rem;
}

.plans-card{
    display: flex;
    gap: 2rem;  
    justify-content: center;  
    color: white;
    margin-top: 5rem;
}

.plans-card div{
    padding: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.plans-card div>i{
    font-size: 3rem;
}

.plans-card div>span:nth-of-type(1){
    font-weight: bold;
}

.price{
    font-weight: bolder;
    font-size: 3rem;
}

.plans-card div img{
    padding-right: 1rem;
}

.plans-card >:nth-child(1),
.plans-card >:nth-child(3){
    background-color: var(--caloryCard);
}

.plans-card >:nth-child(2){
    transform: scale(1.1);
    background-image: linear-gradient(45deg, rgb(255, 174, 0) 10%, rgb(255, 105, 5) 80%);
}

@media screen and (max-width: 769px){
    .plans-title{
        font-size: xx-large;
        text-align: center;
        flex-direction: column;
        gap: 2rem;
    }

    .plans-card{
        flex-direction: column;
        padding: 1rem;
    }

    .plans-card >:nth-child(2){
        transform: scale(1);
    }

    .plans-card div span:nth-of-type(3),
    .plans-card div span:nth-of-type(4),
    .plans-card div span:nth-of-type(5),
    .plans-card div span:nth-of-type(6){
        font-size: small;
    }

    .plans-card div img{
        width: 1.3rem;
    }
}