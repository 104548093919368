.footer>div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    width: 100%;
    height: 25rem;
}

.footer>div>img{
    width: 8%;
}

.footer>div>div img{
    width: 2.3rem;
}

.footer>div>div{
    display: flex;
    gap: 6rem;
}

hr{
    border-width: 2px;
}

@media screen and (max-width: 769px) {
    .footer>div>img{
        width: 50%;
    }

    .footer>div>div{
        gap: 4rem;
    }
}